import { Plan } from '~/utils/rollerup.server';
import PlanOption from '~/components/PlanOption';
import { useReferralCode } from '~/utils/referralCode';
import { useState, useEffect } from 'react';
import { getBillingBasis, BillingBasis } from '~/utils/plans';
import Switcher from './Switcher';

type SwitcherOption<T> = { label: string; value: T };

export default function PricingSection({ plans }: { plans: Plan[] }) {
  const [referralCode] = useReferralCode();
  const [selectedBasis, setSelectedBasis] = useState<BillingBasis>(
    referralCode ? 'monthly' : 'annual',
  );

  const getBillingBases = () => {
    // We're only allowing referral promotions on monthly plans
    if (referralCode) {
      return [
        {
          label: 'Monthly',
          value: 'monthly' as BillingBasis,
        },
      ];
    }

    return plans.reduce((bases: SwitcherOption<BillingBasis>[], plan: Plan) => {
      const basis = getBillingBasis(plan);
      if (!bases.some((option) => option.value === basis)) {
        return [
          ...bases,
          {
            label: basis.charAt(0).toUpperCase() + basis.slice(1),
            value: basis,
            priceSuffix:
              plan.interval === 'month'
                ? '/month'
                : plan.interval === 'year'
                  ? '/year'
                  : '/quarter',
          },
        ];
      }

      return bases;
    }, []);
  };

  const billingBases = getBillingBases();

  useEffect(() => {
    if (referralCode && billingBases.length > 0) {
      setSelectedBasis(billingBases[0].value);
    }
  }, [billingBases, referralCode]);
  const filteredPlans = plans.filter(
    (plan) => getBillingBasis(plan) === selectedBasis,
  );

  return (
    <div className="relative isolate bg-white px-6 py-10 sm:py-16 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-base font-semibold leading-7 text-[#FE5000]">
          Unlimited Wash Subscriptions
        </h2>
      </div>
      <p className="mx-auto mt-3 sm:mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Sign up for an Unlimited Membership and drive a clean car every day.
        Your Unlimited Membership will be active at all 11 Mr Wash Car Wash
        locations.
      </p>

      <Switcher
        options={billingBases}
        defaultValue={selectedBasis}
        onChange={(value) => setSelectedBasis(value as BillingBasis)}
      />

      <div className="mx-auto mt-5 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {filteredPlans.map((tier, tierIdx) => (
          <PlanOption
            key={tier.id}
            plan={tier}
            order={tierIdx + 1}
            href={`/signup/${tier.id}${referralCode && tier.interval === 'month' && tier.intervalCount === 1 ? `?rc=${referralCode}` : ''}`}
          />
        ))}
      </div>
    </div>
  );
}
