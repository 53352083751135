import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import PricingSection from '../components/PricingSection';
import Banner from '~/components/Banner';
import { loader as plansLoader } from '~/routes/api.plans';
import { Outlet, useLoaderData, useLocation } from '@remix-run/react';
import Header from '~/components/Header';
import { usePlansData } from '~/utils/plans';

export const meta: MetaFunction = () => {
  return [{ title: 'Sign Up | Mr Wash Portal' }];
};

export async function loader(args: LoaderFunctionArgs) {
  const response = await plansLoader(args);
  return response;
}

export default function Signup() {
  const location = useLocation();

  // Get initial plans/promotions using the referral code in the query params
  const data = useLoaderData<typeof loader>();

  // Reload the plans if a different referral code is stored on the client
  const { plans, referralPromotion } = usePlansData(data);

  const isReferral = !!referralPromotion;

  if (location.pathname === '/signup' || location.pathname === '/signup/')
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        {isReferral && (
          <Banner
            callout="Referral Program Promotion"
            message="Sign up now and get your first month free"
          />
        )}

        <div className="flex justify-center">
          <PricingSection plans={plans} />
        </div>
      </div>
    );

  return (
    <div className="">
      <Header />
      <main className="py-8 mx-auto max-w-5xl">
        <Outlet />
      </main>
    </div>
  );
}
