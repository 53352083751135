import { useState, useEffect } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';

type SwitcherOption<T> = { label: string; value: T };

interface SwitcherProps<T> {
  options: SwitcherOption<T>[];
  defaultValue: T;
  onChange: (value: T) => void;
}

export default function Switcher<T>({
  options,
  defaultValue,
  onChange,
}: SwitcherProps<T>) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  return (
    <div>
      <div className="mt-16 flex justify-center">
        <fieldset aria-label="Payment frequency">
          <RadioGroup
            value={selectedOption}
            onChange={setSelectedOption}
            className="grid grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200"
          >
            {options.map((option) => (
              <Radio
                key={option.label}
                value={option.value}
                className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-blue-600 data-[checked]:text-white"
              >
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
      </div>
    </div>
  );
}
